// Home.js
import React from 'react';
import { Link } from 'react-router-dom';

const artPrints = [
  { id: 1, src: '/images/prints/baphoment.jpg', title: 'baphoment', description: 'Sabbatic Goat' },
  { id: 2, src: '/images/prints/nomBitch.jpg', title: 'nomBitch', description: 'OmNomNom' },
  { id: 3, src: '/images/prints/ramen.jpg', title: 'ramen', description: 'ramahrahmahmen' },
  { id: 4, src: '/images/prints/properly purplegon be green turq.jpg', title: 'properly purplegon be green turq', description: 'properly purplegon be green turq' },
];

const commissions = [
  { id: 1, src: '/images/commissions/ohdeer.jpg', title: 'Oh Deer', description: 'Description for Oh Deer' },
  { id: 2, src: '/images/commissions/ruminate.jpg', title: 'Ruminate', description: 'Description for Ruminate' },
  { id: 3, src: '/images/commissions/herebe.jpg', title: 'Here Be', description: 'Description for Here Be' },
  { id: 4, src: '/images/commissions/blueMe.jpg', title: 'Blue Me', description: 'Description for Blue Me' },
];

function Home() {
  return (
    <div className="home">
      <section className="bio">
        <h2>About MaaJyyn</h2>
        <p>This emanation is MaaJyyn, The djinna of imagination. A scythe poised to snare the unresolved, wrest them into the hearth of light and enthrall. Their mentality imbued with the irrevocable knowing- ALL may exist.</p>
      </section>
      <section className="art-prints">
        <h2>Art Prints</h2>
        <div className="home-grid">
          {artPrints.map((artwork) => (
            <Link to={`/gallery/print/${artwork.id}`} key={artwork.id} className="grid-item">
              <img src={artwork.src} alt={artwork.title} />
            </Link>
          ))}
        </div>
      </section>
      <section className="commissions">
        <h2>Commissions</h2>
        <div className="home-grid">
          {commissions.map((commission) => (
            <Link to={`/gallery/commission/${commission.id}`} key={commission.id} className="grid-item">
              <img src={commission.src} alt={commission.title} />
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Home;