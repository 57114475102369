// About.js
import React from 'react';

function About() {
  return (
    <div className="about">
      <div className="about-content">
        <div className="about-text">
          <h1>The initial spark of enduring epiphany.</h1>
          <p>Located within the omphalos of bewilderment and uncertainty, where grasping minds meander; A lantern, situated, streams its indefatigable light, impregnating the abysmal dark with rampant certainty.</p>
          <p>This emanation is MaaJyyn, The djinna of imagination. A scythe poised to snare the unresolved, wrest them into the hearth of light and enthrall. Their mentality imbued with the irrevocable knowing- ALL may exist.</p>
          <p>For its impossible to derive sterility from all-encompassing possibility.</p>
          <p>Hence-forth becoming a beacon themselves, to bestow the dull a dawn of inspiration.</p>
        </div>
        <div className="about-image">
          <img src="/images/about-image.jpg" alt="About MaaJyyn" />
        </div>
      </div>
    </div>
  );
}

export default About;