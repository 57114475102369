// Gallery.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const artPrints = [
  { id: 1, src: '/images/prints/baphoment.jpg', title: 'baphoment', description: 'Sabbatic Goat' },
  { id: 2, src: '/images/prints/nomBitch.jpg', title: 'nomBitch', description: 'OmNomNom' },
  { id: 3, src: '/images/prints/ramen.jpg', title: 'ramen', description: 'ramahrahmahmen' },
  { id: 4, src: '/images/prints/properly purplegon be green turq.jpg', title: 'properly purplegon be green turq', description: 'properly purplegon be green turq' },
];

const commissions = [
  { id: 1, src: '/images/commissions/ohdeer.jpg', title: 'Oh Deer', description: 'Description for Oh Deer' },
  { id: 2, src: '/images/commissions/ruminate.jpg', title: 'Ruminate', description: 'Description for Ruminate' },
  { id: 3, src: '/images/commissions/herebe.jpg', title: 'Here Be', description: 'Description for Here Be' },
  { id: 4, src: '/images/commissions/blueMe.jpg', title: 'Blue Me', description: 'Description for Blue Me' },
];

function Gallery() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { type, id } = useParams();
  const artwork = type === 'print'
    ? artPrints.find(art => art.id === parseInt(id))
    : commissions.find(com => com.id === parseInt(id));

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
    document.documentElement.style.height = '100%'; // Set html height to 100%
    document.body.style.height = '100%'; // Set body height to 100%
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto';
    document.documentElement.style.height = ''; // Reset html height
    document.body.style.height = ''; // Reset body height
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!artwork) return <div>Artwork not found</div>;

  return (
    <div className={`gallery ${isModalOpen ? 'modal-open' : ''}`}>
      <div className="gallery-content">
        <div className="gallery-image-container">
          <img
            src={artwork.src}
            alt={artwork.title}
            className="gallery-image"
            onClick={openModal}
          />
        </div>
        <div className="gallery-text">
          <h2>{artwork.title}</h2>
          <p>{artwork.description}</p>
          <Link to="/">Back to Gallery</Link>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={artwork.src}
              alt={artwork.title}
              className="modal-image"
            />
            <button className="close-button" onClick={closeModal}>×</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;